import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/User/Desktop/SignPenFreeWorking/SignPenFree/docs/public/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "list-users"
    }}>{`List Users`}</h1>
    <p>{`Returns a list of Users.`}</p>
    <p><strong parentName="p">{`GET`}</strong>{` `}<inlineCode parentName="p">{`/users/list`}</inlineCode></p>
    <hr></hr>
    <h4 {...{
      "id": "parameter-specifications"
    }}>{`Parameter Specifications`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Key`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. String. The Account's API Key.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Limit`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of objects returned per request`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Offset`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The item number you would like the response to begin with`}</td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`Example Response`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "Result": "1",
  "Error_Messages": {},
  "Account_Id": "d8ff8fad-35a3-4549-8c2c-f3c8e446556c",
  "Data": {
    {
      "User_Id": "b0773472-1752-445f-8d85-740a355ef215",
      "Account_Id": "d8ff8fad-35a3-4549-8c2c-f3c8e446556c",
      "User_Active": 1,
      "User_First_Name": "John",
      "User_Last_Name": "Doe",
      "User_Phone": "5124009186",
      "User_Email": "john.doe@website.com",
      "Created_By_User_Id": "626c6ff7-9f87-492b-8f5b-9155e24e781a",
      "Created_UTC_DateTime": "2021-10-04 15:12:42"
    }
  },
  "Limit": 1,
  "Offset": 0,
  "Total": 1,
  "Query_UTC_DateTime": "2021-10-03 15:12:42"
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      